.tag {
  @apply px-3 py-1 rounded-lg text-xs font-semibold;
}

.primary-tag {
  @apply bg-primary-100 border border-primary-300 text-primary;
}

.secondary-tag {
  @apply bg-gray-200 border border-gray-300 text-primary-dark;
}

.success-tag {
  @apply bg-success-100 border border-success-200 text-success-500;
}

.warning-tag {
  @apply bg-warning-100 border border-warning-200 text-warning-500;
}

.error-tag {
  @apply bg-error-100 border border-error-200 text-error;
}
