/* default classes */
.btn {
  @apply inline-flex items-center cursor-pointer h6 rounded-lg py-2 px-4 justify-center disabled:bg-gray-200 disabled:hover:bg-gray-200 disabled:text-disabled-dark disabled:hover:text-disabled-dark disabled:cursor-not-allowed;
}

/* simple buttons */
.btn-primary {
  @apply btn !text-white bg-primary-400 hover:bg-primary active:bg-primary-500;
}

.btn-secondary {
  @apply btn bg-gray-200 hover:bg-gray-300 active:bg-gray-400;
}

/* buttons with active class */
.btn-primary.active {
  @apply bg-primary-500;
}

.btn-secondary.active {
  @apply bg-gray-400;
}

/* Toggle Button */
.btn-toggle {
  @apply relative inline-flex items-center cursor-pointer;
}

.btn-toggle-label {
  @apply mr-3 h6 text-secondary-dark;
}

.btn-toggle-toggler {
  @apply w-8 h-6 bg-gray-300 p-1 rounded-full peer-checked:bg-primary-400 peer-checked:after:translate-x-[135%] after:content-[''] after:absolute after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all;
}
