.assets-list {
  @apply my-4;

  .asset-card {
    @apply grid grid-cols-3 p3 bg-gray-100 rounded-lg p-3 mb-4;

    .asset-card-item {
      @apply flex flex-col items-start;
    }
  }
}
