/* typography */
body {
  @apply font-sans text-primary-dark;
}

h1,
.h1 {
  @apply font-bold text-3xl;
}

h2,
.h2 {
  @apply font-semibold text-2xl;
}

h3,
.h3 {
  @apply font-semibold text-xl;
}

h4,
.h4 {
  @apply font-semibold text-lg;
}

h5,
.h5 {
  @apply font-semibold text-md;
}

h6,
.h6 {
  @apply font-semibold text-sm;
}

.prose-a {
  @apply underline text-primary-400 hover:text-primary-500;
}

.p1 {
  @apply font-normal text-md;
}

.p2 {
  @apply font-normal text-sm;
}

.p3 {
  @apply font-normal text-xs;
}
