/* sidebar and map section */
.location-map-section {
  @apply grid grid-cols-5 flex-grow;
}

/* Map */
.map {
  @apply col-span-3;

  .mapboxgl-popup-content {
    @apply border max-w-xs w-fit flex justify-between items-start !rounded-md w-[12rem];
  }

  .mapboxgl-popup-close-button {
    @apply !relative !ml-3;
  }

  .mapboxgl-ctrl-group .mapboxgl-ctrl-compass,
  .mapboxgl-ctrl-logo,
  .mapboxgl-ctrl-attrib {
    @apply hidden;
  }
}

/* Sidebar */
.sidebar {
  @apply col-span-2 px-4 pt-4 h-[95vh];

  .search {
    @apply pb-[40px];
  }

  .sidebar-card {
    @apply mb-2;

    .main-card {
      @apply rounded-lg bg-white px-4 py-3 hover:bg-primary-400 hover:text-white;

      &:hover svg {
        @apply fill-white
      }

      &:hover .timestamp {
        @apply !text-white;
      }

      .card-title {
        @apply flex items-center p3 mb-1;

        svg {
          @apply w-4 fill-secondary-dark mr-2;
        }
      }

      .card-body {
        @apply flex items-center justify-between;

        .status-bar {
          @apply flex flex-col items-end gap-2;

          .timestamp {
            @apply text-xs text-end;
          }

          .timestamp-warning {
            @apply text-warning;
          }

          .timestamp-offline {
            @apply text-error;
          }
        }
      }
    }

    .secondry-card {
      @apply rounded-b-lg bg-white w-[98%] mx-auto mb-4;

      .asset-table {
        @apply w-full;
      }

      .asset-table tr {
        @apply border;
      }

      .asset-table td {
        @apply p-3;

        &:last-child {
          @apply w-1/6 text-right;
        }
      }
    }
  }
}

/* Location show page */
.location {
  @apply grid grid-cols-2 gap-4 p-4;

  .header-container {
    @apply bg-white rounded-lg p-4 mb-4 col-span-2 flex items-center;

    svg {
      @apply w-5;
    }

    .header-icon {
      @apply flex flex-col items-center mr-3;

      span {
        @apply p3 text-secondary-dark;
      }
    }
  }

  .left-container {
    @apply flex flex-col;

    .map-section .map {
      @apply h-[25vh] mt-2;
    }
  }

  .right-container {
    @apply bg-white rounded-lg px-4 py-5;
  }
}
