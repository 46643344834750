@import '@fortawesome/fontawesome-free/css/all.css';
@import 'mapbox-gl/dist/mapbox-gl.css';
@import 'tom-select/dist/css/tom-select.min.css';

@import 'tailwindcss/base';
@import 'tailwindcss/components';

@import 'components/asset_cards.css';
@import 'components/buttons.css';
@import 'components/tags.css';
@import 'components/typography.css';

@import './main.css';

@import 'tailwindcss/utilities';


html {
  @apply bg-gray-100;
}

.nav-items a {
  @apply mx-1;
}
